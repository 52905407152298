import { getActiveAccount } from './cookie'
import { ACCOUNT_PERSONAL } from './constants'

const populateSingle = (single, included) => {
  // May not have relationships array if not relationships
  if (single.relationships) {
    const relationships = single.relationships
    Object.keys(relationships).forEach((key) => {
      // If relationship has just single value, we force
      // it as array so we can use same code
      const isMultiRelation = Array.isArray(relationships[key].data)
      let relationshipData = isMultiRelation ? relationships[key].data : [relationships[key].data]
      relationshipData = relationshipData.map((data) => {
        const relatedIncluded = included.find(inc => inc.type === data.type && inc.id === data.id)
        if (relatedIncluded) {
          return populateSingle(relatedIncluded, included)
        }

        return populateSingle(data, included)
      })

      relationships[key].data = isMultiRelation ? relationshipData : relationshipData[0]
    })

    single.relationships = relationships
  }

  return single
}

/**
 * Helper method for populating relationship data from json-api
 * response. Data parameter should contain object with keys "data" and "included".
 *
 *
 * @param {Object} data
 */
export const populateRelationships = ({ data, included, ...others }) => {
  if (Array.isArray(data)) {
    data = data.map(d => populateSingle(d, included))
  } else {
    data = populateSingle(data, included)
  }

  return {
    data,
    included,
    ...others
  }
}

/**
 * Returns route for paths  which have account prefixed url. For example client invoices will get fetched from /me/invoices
 * but invoices for companies from /me/companies/xx/invoices.
 *
 * @param {string} path For eq. "/invoices"
 */
export const getRouteForActiveAccount = (path) => {
  const activeAccount = getActiveAccount()
  const base = activeAccount === ACCOUNT_PERSONAL ? '/me' : `/me/companies/${activeAccount}`
  return `${base}${path}`
}
