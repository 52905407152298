import { clearState } from '~/utils/state'
import { populateRelationships } from '~/utils/api'

export const state = () => ({
  signInvitations: [],
  signInvitationsLoading: false,
  pendingSignInvitations: [],
  pendingSignInvitationsLoading: false,
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_SIGN_INVITATIONS (state, invitations) {
    state.signInvitations = invitations
  },
  SET_SIGN_INVITATIONS_LOADING (state, loading) {
    state.signInvitationsLoading = loading
  },
  SET_PENDING_SIGN_INVITATIONS (state, invitations) {
    state.pendingSignInvitations = invitations
  },
  SET_PENDING_SIGN_INVITATIONS_LOADING (state, loading) {
    state.pendingSignInvitationsLoading = loading
  },
}

export const actions = {
  async fetchSignInvitations ({ commit, state }) {
    if (state.signInvitationsLoading) { return }

    await commit('SET_SIGN_INVITATIONS_LOADING', true)

    try {
      const response = await this.$axios.$get('/me/sign-invitations?include=contract,permission&signatureState=waiting').then(populateRelationships)
      await commit('SET_SIGN_INVITATIONS', response.data)
    } catch (er) {
      this.$log.error(er)
    } finally {
      await commit('SET_SIGN_INVITATIONS_LOADING', false)
    }
  },
  async fetchPendingSignInvitations ({ commit, state }) {
    if (state.pendingSignInvitationsLoading) { return }

    await commit('SET_PENDING_SIGN_INVITATIONS_LOADING', true)

    try {
      const response = await this.$axios.$get('/me/sign-invitations?signatureState=waiting')
      await commit('SET_PENDING_SIGN_INVITATIONS', response.data)
    } catch (er) {
      this.$log.error(er)
    } finally {
      await commit('SET_PENDING_SIGN_INVITATIONS_LOADING', false)
    }
  },
}

export const getters = {
  signInvitations (state) {
    return state.signInvitations
  },
  pendingSignInvitationCount (state) {
    return state.pendingSignInvitations.length
  }
}
