import sortBy from 'lodash.sortby'
import { clearState } from '~/utils/state'
import { populateRelationships } from '~/utils/api'
import { getActiveAccount } from '~/utils/cookie'
import { ACCOUNT_PERSONAL } from '~/utils/constants'

export const state = () => ({
  favourites: [],
  favouritesLoaded: false,
  favouritesLoading: false,
  templatesById: {},
  userTemplates: [],
  userTemplatesLoaded: false,
  tailoredTemplates: [],
  tailoredTemplatesLoaded: false,
  categories: [],
  categoriesLoaded: false,
  templateCollection: null,
  templateCollectionLoaded: false,
  templateCollectionTemplates: [],
  visibleTooltip: null
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_FAVOURITES_LOADING (state, isLoading) {
    state.favouritesLoading = isLoading
  },
  SET_FAVOURITES (state, favourites) {
    state.favourites = favourites
    state.favouritesLoaded = true
    state.favouritesLoading = false
  },
  ADD_FAVOURITE (state, favourite) {
    state.favourites.push(favourite)
  },
  REMOVE_FAVOURITE (state, favouriteId) {
    state.favourites = state.favourites.filter(f => f.id !== favouriteId)
  },
  ADD_TEMPLATE (state, template) {
    state.templatesById[template.id] = template
  },
  ADD_TEMPLATES (state, templates) {
    templates.forEach((t) => {
      state.templatesById[t.id] = t
    })
  },
  SET_USER_TEMPLATES (state, templates) {
    state.userTemplates = templates
    state.userTemplatesLoaded = true
  },
  DELETE_USER_TEMPLATE (state, id) {
    state.userTemplates = state.userTemplates.filter(t => t.id !== id)
  },
  UPDATE_USER_TEMPLATE (state, template) {
    state.userTemplates = state.userTemplates.map((t) => {
      if (t.id === template.id) { return template } else { return t }
    })
  },
  SET_TAILORED_TEMPLATES (state, templates) {
    state.tailoredTemplates = templates
    state.tailoredTemplatesLoaded = true
  },
  SET_CATEGORIES (state, categories) {
    state.categories = categories
    state.categoriesLoaded = true
  },
  SET_TEMPLATE_COLLECTION_AND_TEMPLATES (state, { collection, templates }) {
    state.templateCollection = collection
    state.templateCollectionTemplates = templates
    state.templateCollectionLoaded = true
  },
  SET_INFO (state, info) {
    state.info = info
  },
  SET_VISIBLE_TOOLTIP (state, tooltip) {
    state.visibleTooltip = tooltip
  }
}

export const actions = {
  async fetchFavourites ({ commit }) {
    await commit('SET_FAVOURITES_LOADING', true)
    const response = await this.$axios.$get('/me/favourites?include=template&limit=999').then(populateRelationships)
    await commit('SET_FAVOURITES', response.data)
  },
  async addFavourite ({ commit }, templateId) {
    const response = await this.$axios.$post('/me/favourites?include=template', {
      data: {
        templateId
      }
    }).then(populateRelationships)
    await commit('ADD_FAVOURITE', response.data)
  },
  async removeFavourite ({ state, commit }, templateId) {
    const favourite = state.favourites.find(f => f.relationships.template.data.id === templateId)
    if (!favourite) { throw new Error('Favourite not found for template: ' + templateId) }

    await this.$axios.$delete(`/me/favourites/${favourite.id}`, {
      data: {
        templateId
      }
    })
    await commit('REMOVE_FAVOURITE', favourite.id)
  },
  async fetchTemplate ({ commit }, id) {
    const response = await this.$axios.$get(`/me/templates/${id}?include=categories`).then(populateRelationships)
    await commit('ADD_TEMPLATE', response.data)
  },
  async fetchRelatedTemplates ({ commit, state }, templateId) {
    const template = state.templatesById[templateId]
    if (!template) { throw new Error('Template not found') }

    const response = await this.$axios.$get(`/templates/${templateId}/related?include=categories`).then(populateRelationships)
    await commit('ADD_TEMPLATES', response.data)

    return response.data
  },
  async fetchTailoredTemplates ({ commit }) {
    // Do not fetch tailored templates for clients
    if (getActiveAccount() === ACCOUNT_PERSONAL) {
      await commit('SET_TAILORED_TEMPLATES', [])
    } else {
      const response = await this.$axios.$get('/me/templates?limit=999').then(populateRelationships)
      await commit('SET_TAILORED_TEMPLATES', response.data)
    }
  },
  async fetchUserTemplates ({ commit }) {
    const response = await this.$axios.$get('/me/userTemplates?include=template&limit=999').then(populateRelationships)
    await commit('SET_USER_TEMPLATES', response.data)
  },
  async fetchCategories ({ commit }) {
    const response = await this.$axios.$get('/categories')

    // Little hack for supporting new simplified category listing
    // where we have only one level categories. If we still have the old category
    // hierarchy we fallback to categorie under "company" main category
    // This can be removed after we have cleaned categories from backend.
    // TODO: Clean after backend returns only one level categories
    const parentCategoryCount = response.data.filter(c => !c.attributes.categoryId).length

    if (parentCategoryCount > 2) {
      // new category listing with only "parent categories"
      await commit('SET_CATEGORIES', response.data)
    } else {
      // old category listing, fallback to categories on "company" parent category
      const parentCategory = response.data.find(c => ['yrityssopimukset', 'company-documents', 'dokument-for-foretag'].includes(c.attributes.slug))
      await commit('SET_CATEGORIES', response.data.filter(c => c.attributes.categoryId === parentCategory.id))
    }
  },
  async fetchTemplateCollection ({ commit }) {
    const activeAccount = getActiveAccount()

    const collection = await this.$axios.$get(
      activeAccount === ACCOUNT_PERSONAL
        ? '/me/templateCollections'
        : `/me/companies/${activeAccount}/templateCollections`
    ).then(r => r.data[0])

    if (!collection) {
      await commit('SET_TEMPLATE_COLLECTION_AND_TEMPLATES', { collection: null, templates: [] })
      return
    }

    const templates = await this.$axios.$get(
      activeAccount === ACCOUNT_PERSONAL
        ? `/me/templateCollections/${collection.id}/templates`
        : `/me/companies/${activeAccount}/templateCollections/${collection.id}/templates`
    ).then(r => r.data)

    await commit('SET_TEMPLATE_COLLECTION_AND_TEMPLATES', { collection, templates })
  },
  async deleteUserTemplate ({ commit }, id) {
    await this.$axios.$delete(`/me/userTemplates/${id}`)
    await commit('DELETE_USER_TEMPLATE', id)
  },
  async updateUserTemplate ({ commit }, { id, name }) {
    const template = await this.$axios.$put(`/me/userTemplates/${id}`, { name })
    await commit('UPDATE_USER_TEMPLATE', template.data)
  }
}

export const getters = {
  isTemplateInFavourites: state => (templateId) => {
    return !!state.favourites.find(f => f.relationships.template.data.id === templateId)
  },
  favourites (state) {
    return sortBy(state.favourites, f => f.relationships.template.data.attributes.name)
  },
  shouldLoadFavourites (state) {
    return !state.favouritesLoaded && !state.favouritesLoading
  }
}
