import { clearState } from '~/utils/state'
import { getRouteForActiveAccount } from '~/utils/api'

export const state = () => ({
  invoices: []
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_INVOICES (state, invoices) {
    state.invoices = invoices
  }
}

export const actions = {
  async fetchInvoices ({ commit }) {
    const response = await this.$axios.$get(getRouteForActiveAccount('/invoices?limit=99'))
    await commit('SET_INVOICES', response.data)
  },
  getPdf (_, invoiceId) {
    return this.$axios.$get(getRouteForActiveAccount(`/invoices/${invoiceId}/pdf`))
  },
  async collect ({ dispatch }, data) {
    await this.$axios.$post(getRouteForActiveAccount('/invoices/collect'), data)
    await dispatch('fetchInvoices')
  }
}

export const getters = {
}
