import _typeof from "@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.array.concat.js";
export default (function (_ref, inject) {
  var app = _ref.app,
    store = _ref.store;
  var i18n = app === null || app === void 0 ? void 0 : app.i18n;

  /**
   * @param {string | { key: string; country?: string; pathOnly?: boolean }} keyOrConfig
   */
  var translate = function translate(keyOrConfig) {
    var _keyOrConfig$country, _keyOrConfig$pathOnly;
    var isConfig = _typeof(keyOrConfig) === 'object';
    var key = !isConfig ? keyOrConfig : keyOrConfig.key;
    var country = (_keyOrConfig$country = keyOrConfig === null || keyOrConfig === void 0 ? void 0 : keyOrConfig.country) !== null && _keyOrConfig$country !== void 0 ? _keyOrConfig$country : store.getters['auth/country'];
    var pathOnly = (_keyOrConfig$pathOnly = keyOrConfig === null || keyOrConfig === void 0 ? void 0 : keyOrConfig.pathOnly) !== null && _keyOrConfig$pathOnly !== void 0 ? _keyOrConfig$pathOnly : false;
    if (!key) {
      throw new Error('Key must be provided for $translate!');
    }
    if (!i18n) {
      return;
    }
    var path = "c.country-aware.".concat(key, ".").concat(country);
    if (i18n.te(path) && i18n.t(path)) {
      return pathOnly ? path : i18n.t(path);
    }
    return pathOnly ? "c.country-aware.".concat(key, ".default") : i18n.t("c.country-aware.".concat(key, ".default"));
  };
  inject('translate', translate);
});