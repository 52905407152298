import { clearState } from '~/utils/state'
import { getRouteForActiveAccount } from '~/utils/api'
import { ROLE_ADMIN } from '~/utils/constants'

export const state = () => ({
  roles: [],
  rolesLoading: false,
  licenses: {
    user: {
      used: 0,
      allowed: -1,
    },
    builder: {
      used: 0,
      allowed: -1,
    },
    workspace: {
      used: 0,
      allowed: -1
    }
  },
  licensesLoading: false
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_ROLES_LOADING (state, loading) {
    state.rolesLoading = loading
  },
  SET_ROLES (state, roles) {
    state.roles = roles
  },
  SET_LICENSES_LOADING (state, loading) {
    state.seatsLoading = loading
  },
  SET_LICENSES (state, licenses) {
    state.licenses = licenses
  }
}

export const actions = {
  async fetchRoles ({ commit, state, rootState }, { force = false } = {}) {
    if (state.rolesLoading) { return }
    if (state.roles.length && !force) { return }

    await commit('SET_ROLES_LOADING', true)

    // TODO: Add pagination support for roles, api supports already but we need UI support also
    const response = await this.$axios.$get(getRouteForActiveAccount('/roles?limit=999'))

    const sortedRoles = response.data.sort((a, b) => a.attributes.title.toLowerCase().localeCompare(b.attributes.title.toLowerCase(), rootState.language)).reduce((acc, cur) => {
      if (cur.attributes.type === ROLE_ADMIN) {
        return [cur, ...acc]
      }
      return [...acc, cur]
    }, [])

    await commit('SET_ROLES', sortedRoles)
    await commit('SET_ROLES_LOADING', false)
  },
  async fetchLicenses ({ commit, state }) {
    if (state.seatsLoading) { return }

    await commit('SET_LICENSES_LOADING', true)
    const response = await this.$axios.$get('/me/licenses')
    await commit('SET_LICENSES', response.data)
    await commit('SET_LICENSES_LOADING', false)
  }
}

export const getters = {
  licenses ({ licenses: { user = {}, builder = {} } = {} }) {
    return {
      user: {
        available: user?.allowed === -1 || user?.used < user?.allowed,
        ...user
      },
      builder: {
        available: builder?.allowed === -1 || builder?.used < builder.allowed,
        ...builder
      }
    }
  },
}
