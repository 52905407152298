import { render, staticRenderFns } from "./CookieControl.vue?vue&type=template&id=725f4e8b&"
import script from "./CookieControl.vue?vue&type=script&lang=js&"
export * from "./CookieControl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DTSwitch: require('/vercel/path0/node_modules/@docue/docue-ui/src/components/DTSwitch/DTSwitch.vue').default})
