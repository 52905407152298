import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export default (function (_ref, inject) {
  var app = _ref.app;
  var appId = process.env.INTERCOM_APP_ID;
  if (!window.Intercom) {
    var Intercom = function Intercom() {
      Intercom.c(arguments);
    };
    Intercom.q = [];
    Intercom.c = function (args) {
      Intercom.q.push(args);
    };
    window.Intercom = Intercom;
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.intercom.io/widget/' + appId;
    document.body.appendChild(script);
  }
  inject('intercom', {
    boot: function boot() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (!window.Intercom) {
        return;
      }
      window.Intercom('boot', _objectSpread({
        app_id: appId
      }, options));
    },
    call: function call() {
      var _window;
      if (!window.Intercom) {
        return;
      }
      (_window = window).Intercom.apply(_window, arguments);
    }
  });
});