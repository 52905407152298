import get from 'lodash.get'
import { LOGIN_PATH, ROUTES_ALLOWED_WITHOUT_WORKSPACE } from '~/utils/constants'
import {
  getApiToken,
  getActiveAccount
} from '~/utils/cookie'

function loginUrl () {
  const loginUrl = new URL(LOGIN_PATH, window.location.origin)
  loginUrl.searchParams.set('return', window.location.href)

  return loginUrl.toString()
}

export const AUTH_ACTIVE_ACCOUNT_ID_KEY = 'auth-active-account-id'

export default async function ({ redirect, store, route, $ph, $config }) {
  const optional = get(route, 'meta.0.auth.optional', false)

  // Add event listener for localStorage in order to detect
  // active account changes in other tabs
  addEventListener('storage', storageListener)

  const hasToken = getApiToken() && getActiveAccount()
  if (!optional && !hasToken) {
    // Fail early if no token or active account
    return redirect(loginUrl())
  }

  try {
    hasToken && await store.commit('auth/SET_ACTIVE_ACCOUNT', getActiveAccount())
    hasToken && await store.dispatch('auth/fetchAccountData')
  } catch (e) {
    if (!optional) {
      return redirect(loginUrl())
    }
  }

  const client = store.state.auth.client
  const hasCompany = store.state.auth.company || store.state.auth.companies?.length || (client && (await store.dispatch('auth/fetchCompanies')).length)
  const account = store.getters['auth/account']
  if (client && account) {
    // Set active account key to localStorage to dispatch change event
    // for other potentially open browser tabs
    localStorage.setItem(AUTH_ACTIVE_ACCOUNT_ID_KEY, account.type + '-' + account.id)
  }

  const distinctId = $ph.get_distinct_id()
  if (client && distinctId && client.id !== distinctId) {
    $ph.identify(client.id, {
      email: client.attributes.email,
      app_v1_version: $config.APP_VERSION,
    })
  }

  if (optional) {
    return
  }

  // redirect into create-workspace if no workspaces found and client doesn't have customerId
  if (
    !hasCompany &&
    !client?.attributes?.customerId &&
    !['/signup/create-workspace', '/signup/create-user'].includes(route.path) &&
    !ROUTES_ALLOWED_WITHOUT_WORKSPACE.includes(route.path)
  ) {
    return redirect('/signup/create-workspace?fallback=true')
  }
}

function storageListener (e) {
  if (e.key === AUTH_ACTIVE_ACCOUNT_ID_KEY) {
    // If id changes and there is old value and new value, that
    // means that the id changed in another tab and we have to reload
    if (e.oldValue && e.newValue) {
      location.reload()
    }
  }
}
