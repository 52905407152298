import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import { ALL_LANGUAGES, ALL_COUNTRIES, LANGUAGE_EN } from '~/utils/constants';
export default (function (_ref, inject) {
  var store = _ref.store;
  var locale = function locale() {
    var _window$navigator$lan, _window, _window$navigator, _window$navigator$lan2;
    var authUserLocale = store.getters['auth/locale'];

    /**
     * if user is not authenticated value will be something like 'fi-undefined',
     * check it and fallback to browser default or en
     */
    var parts = authUserLocale.split('-').filter(function (x) {
      return [].concat(_toConsumableArray(ALL_LANGUAGES), _toConsumableArray(ALL_COUNTRIES)).includes(x);
    });
    if (parts.length === 2) {
      return parts.join('-');
    }
    return (_window$navigator$lan = (_window = window) === null || _window === void 0 ? void 0 : (_window$navigator = _window.navigator) === null || _window$navigator === void 0 ? void 0 : (_window$navigator$lan2 = _window$navigator.languages) === null || _window$navigator$lan2 === void 0 ? void 0 : _window$navigator$lan2[0]) !== null && _window$navigator$lan !== void 0 ? _window$navigator$lan : LANGUAGE_EN;
  };

  /**
   * @returns currency options for formatting currency values
   */
  var currencyOptions = function currencyOptions() {
    var config = store.state.auth.configuration;
    var currency = (config === null || config === void 0 ? void 0 : config.currency_code) || 'EUR';
    return {
      style: 'currency',
      currency: currency
    };
  };

  /**
   * @param {Date | string} date value to be formatted
   * @param {Intl.DateTimeFormatOptions} opts options for formatting date value
   * @returns formatted date string
   */
  var formatDate = function formatDate(date, opts) {
    return new Intl.DateTimeFormat(locale(), opts).format(date instanceof Date ? date : new Date(date));
  };

  /**
   * @param {number} amount value to be formatted
   * @param {Intl.NumberFormatOptions} opts options for formatting amount value
   * @returns formatted amount string
   */
  var formatCurrency = function formatCurrency(amount, opts) {
    return new Intl.NumberFormat(locale(), _objectSpread(_objectSpread({}, opts), currencyOptions())).format(amount);
  };
  inject('intl', {
    formatDate: formatDate,
    formatCurrency: formatCurrency
  });
});