
export default {
  props: {
    backButtonText: {
      type: String,
      required: true
    },
    backButtonAction: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    isLoading () {
      return this.$store.state.loadingCount > 0
    }
  }
}
