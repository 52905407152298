import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.starts-with.js";
import * as constants from '~/utils/constants';
export default function (ctx, inject) {
  var features = {};
  Object.keys(constants).filter(function (constant) {
    return constant.startsWith('FEATURE_');
  }).forEach(function (constant) {
    features[constant] = constants[constant];
  });
  inject('features', new Proxy({}, {
    get: function get(target, name) {
      if (features[name]) {
        return features[name];
      }
      throw new Error('Feature not found: ' + name);
    }
  }));
}