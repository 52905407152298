export const ACTION_EDIT_SIGNATURE = 'editSignature'
export const ACTION_GO_TO_INFO_SERVICES = 'goToInfoServices'
export const ACTION_GO_TO_ACCEPT_CHAT_INVITE = 'goToAcceptChatInvite'
export const ACTION_GO_TO_ACCEPT_COMPANY_INVITE = 'goToAcceptCompanyInvite'
export const ACTION_SHOW_ON_BOARD_DEMO = 'showOnBoardDemo'
export const ACTION_GO_TO_COMPANY_ADD = 'goToCompanyAdd'
export const ACTION_GO_TO_SET_PASSWORD = 'goToSetPassword'
export const ACTION_REMINDER = 'reminder'
export const ACTION_GOTO_CHAT = 'gotoChat'
export const ACTION_GO_TO_CONTRACT_SIGNING = 'goToContractSigning'

export const DISABLED_NOTIFICATION_TYPES = [
  ACTION_SHOW_ON_BOARD_DEMO,
  ACTION_REMINDER,
  ACTION_GOTO_CHAT,
  ACTION_GO_TO_CONTRACT_SIGNING,
  ACTION_GO_TO_ACCEPT_COMPANY_INVITE
]

export const filterDisabled = (notification) => {
  return !DISABLED_NOTIFICATION_TYPES.includes(notification.attributes.data.action)
}

export const systemNotifications = {
  noPassword: {
    id: 'noPassword',
    attributes: {
      readAt: null,
      systemNotification: true,
      data: {
        action: 'goToSetPassword',
        actionText: 'c.notifications.system.noPassword.actionText',
        title: 'c.notifications.system.noPassword.title',
        body: 'c.notifications.system.noPassword.body'
      }
    }
  }
}

export const notificationIcons = {
  reminder: {
    icon: 'fa-clock',
    iconColor: '#C8AB1D'
  },
  showOnBoardDemo: {
    icon: 'fa-video',
    iconColor: '#FF5151'
  },
  gotoChat: {
    icon: 'fa-envelope',
    iconColor: '#FF5151'
  },
  goToCompanyAdd: {
    icon: 'fa-building',
    iconColor: '#66B41C'
  },
  goToInfoServices: {
    icon: 'fa-university',
    iconColor: '#FF5151'
  },
  editSignature: {
    icon: 'fa-pencil',
    iconColor: '#66B41C'
  },
  goToContractSigning: {
    icon: 'fa-pencil',
    iconColor: '#b2b016'
  },
  goToAcceptChatInvite: {
    icon: 'fa-comment',
    iconColor: '#FF5151'
  },
  goToAcceptCompanyInvite: {
    icon: 'fa-envelope',
    iconColor: '#FF5151'
  },
  goToSetPassword: {
    icon: 'fa-exclamation',
    iconColor: '#F03C3C'
  },
  goToSetPaymentDetails: {
    icon: 'fa-exclamation',
    iconColor: '#F03C3C'
  }
}
