import words from 'lodash.words'

export const capitalizeWords = (string) => {
  const ws = words(string)
  return ws.reduce((result, word, index) => {
    return result + (index ? ' ' : '') + capitalize(word)
  }, '')
}

// this could be replaced with _.capitalize
export const capitalize = string => string.charAt(0).toUpperCase() + string.substring(1)

export const removeExtension = (name) => {
  const parts = name.split('.')

  // Return just first element if no other parts
  if (parts.length === 1) {
    return parts[0]
  }

  // Return all but the last part joined
  return parts.slice(0, -1).join('.')
}

/**
 * Validates swedish company identification numbers.
 * Chooses the actual validation algorithm based on the string signature.
 * - @see {@link validateFinnishCompanyIdent}
 * - @see {@link validateSwedishCompanyIdent}
 *
 * @param {string} value
 * @returns True if value is valid, false otherwise
 */
export const validateCompanyIdent = (value) => {
  switch (true) {
    case !!value.match(/^[0-9]{7}-[0-9]{1}$/)?.length:
      return validateFinnishCompanyIdent(value)
    case !!value.match(/^[0-9]{6}-[0-9]{4}$/)?.length:
      return validateSwedishCompanyIdent(value)
    default:
      return true
  }
}

/**
 * Validates swedish company identification numbers.
 *
 * @param {string} value
 * @returns {boolean} True if value is valid, false otherwise
 */
export const validateSwedishCompanyIdent = (value) => {
  if (!value || value.length < 11) {
    return false
  }
  /**
   * Swedish identification numbers are validated with Luhn's modulus 10 algorithm.
   * The checksum is the number required to make the sum % 10 === 0, so we can just
   * veify that it in fact does so.
   */
  const check = parseInt(value.substr(-1, 1))
  const sum = value
    .substr(0, 10)
    .replace(/\D/, '')
    .split('')
    .reverse()
    .map(num => parseInt(num, 10))
    .map((num, i) => {
      if ((i & 1) === 0) {
        num *= 2
      }
      if (num > 9) {
        num -= 9
      }
      return num
    })
    .reduce((sum, num) => (sum += num), 0)
  return (sum + check) % 10 === 0
}

/**
 * Validates finnish company identification numbers.
 *
 * @param {string} value
 * @returns {boolean} True if value is valid, false otherwise
 */
export const validateFinnishCompanyIdent = (value) => {
  if (!value || value.length < 9) {
    return false
  }

  const multipliers = [7, 9, 10, 5, 8, 4, 2]
  const numbers = value.substr(0, multipliers.length).split('').map(Number)
  const check = parseInt(value.substr(-1, 1))
  const sum = numbers.reduce((acc, cur, i) => acc + (cur * multipliers[i]), 0)

  if (isNaN(sum)) {
    return false
  }

  const ident = sum % 11

  if (ident === 1) {
    return false
  }

  if (ident !== 0 && (11 - ident) !== check) {
    return false
  }

  return true
}
