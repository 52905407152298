import { filterDisabled, systemNotifications } from '~/utils/notifications'
import { clearState } from '~/utils/state'

export const state = () => ({
  notifications: [],
  nextPage: 1,
  loading: false
})

export const mutations = {
  CLEAR: clearState(state()),
  ADD_NOTIFICATIONS (state, { data, meta }) {
    state.notifications = state.notifications.concat(data)

    // Set next page to falsy value if we are on the last page
    state.nextPage = meta.current_page === meta.total_pages ? -1 : meta.current_page + 1
  },
  MARK_AS_READ (state, id) {
    state.notifications.map((notification) => {
      if (notification.id === id) {
        notification.attributes.readAt = new Date()
      }

      return notification
    })
  },
  CLEAR_NOTIFICATONS (state) {
    state.notifications = []
    state.nextPage = 1
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

export const actions = {
  async fetchMoreNotifications ({ commit, state, rootGetters }) {
    if (state.nextPage > 0 && state.loading === false) {
      try {
        await commit('SET_LOADING', true)
        const response = await this.$axios.$get(`/me/notifications?page=${state.nextPage}`)

        const account = rootGetters['auth/account']

        // Filter notifications only for current account
        response.data = response.data.filter((notification, index) => {
          if (!notification.attributes.data.requiredAccount) { return true }
          if (!notification.attributes.data.requiredId) { return true }

          return notification.attributes.data.requiredAccount === account.type && notification.attributes.data.requiredId === account.id
        })

        await commit('ADD_NOTIFICATIONS', response)
      } catch (e) {
        this.$log.error(e)
      } finally {
        await commit('SET_LOADING', false)
      }
    }
  },
  async markAsRead ({ commit, state }, id) {
    await this.$axios.$put(`/me/notifications/${id}/markAsRead`)
    await commit('MARK_AS_READ', id)
  }
}

export const getters = {
  unreadNotificationCount (state, getters) {
    return getters.notifications.filter(notification => notification.attributes.readAt === null).length
  },
  notifications (state, getters, rootState, rootGetters) {
    const notifications = state.notifications.filter(filterDisabled)

    // Add system notifications

    //
    // Check if client has password
    //
    const client = rootState.auth.client
    const hasAuthProvider = client?.relationships?.authProviders?.data?.length > 0 || false

    if (client && !client.attributes.hasPassword && !hasAuthProvider) { notifications.unshift(systemNotifications.noPassword) }

    return notifications
  }
}
