import cloneDeep from 'lodash.clonedeep'
import { isSubscriptionPaused, isSubscriptionValid, isSubscriptionSetup, isSubscriptionFuture } from '~/utils/subscription'
import {
  PAYMENT_METHOD_PARTNER,
  ROLE_ADMIN,
} from '~/utils/constants'

export const canManageSubscription = (company, subscription) => {
  const result = {
    status: true,
    reason: null
  }

  const isAdmin = company?.relationships?.role?.data?.attributes?.type === ROLE_ADMIN
  const isUpgrade = isSubscriptionValid(subscription)
  const isPaidViaPartner = company?.attributes?.paymentMethod === PAYMENT_METHOD_PARTNER
  const hasCustomPricing = company?.attributes?.hasCustomPricing

  if (!company) {
    result.reason = 'c.subscribe.cannot-manage-reason.not-company'
    result.status = false
  } else if (company.attributes.hasParentCompany) {
    result.reason = 'c.subscribe.cannot-manage-reason.sub-account'
    result.status = false
  } else if (isSubscriptionPaused(subscription)) {
    result.reason = 'c.subscribe.cannot-manage-reason.paused'
    result.status = false
  } else if (isSubscriptionSetup(subscription)) {
    result.reason = 'c.subscribe.cannot-manage-reason.setup'
    result.status = false
  } else if (isSubscriptionFuture(subscription)) {
    result.reason = 'c.subscribe.cannot-manage-reason.future'
    result.status = false
  } else if (!isAdmin) {
    result.reason = 'c.subscribe.cannot-manage-reason.not-admin'
    result.status = false
  } else if (isUpgrade && isPaidViaPartner) {
    result.reason = 'c.subscribe.cannot-manage-reason.via-partner'
    result.status = false
  } else if (hasCustomPricing) {
    result.reason = 'c.subscribe.cannot-manage-reason.custom-pricing'
    result.status = false
  }

  return result
}

export const checkoutGtmEvent = (event, item, price, configuration) => {
  return {
    event,
    ecommerce: {
      currency: configuration.currency_code,
      value: price.monthly_price * 12 / 100,
      items: [{
        item_id: price.id,
        item_name: item.title,
        price: price.monthly_price * 12 / 100,
        item_category: 'plan',
        quantity: 1,
      }]
    }
  }
}

/**
 * Populates correct addon prices directly into
 * feature_configs based on the current period.
 */
export const getFeatureConfigs = (priceList, periodUnit) => {
  if (!priceList || !priceList.data.feature_configs) {
    return []
  }

  const handleOption = (option) => {
    if (option.type === 'group') {
      option.options = option.options.map(handleOption)
      return option
    }

    const addon = priceList.addons.find(addon => addon.addon_id === option.addon_id)
    if (!addon) {
      return option
    }

    option.price = addon.prices.find(price => price.period_unit === periodUnit)
    return option
  }

  return cloneDeep(priceList.data.feature_configs).map((config) => {
    config.options = config.options.map(handleOption)
    return config
  }).filter(config => !!config)
}

/**
 * Populates human readable labels for options
 * based on addon price and original feature value
 */
export const getFeatureOptions = (featureConfig, originalFeatureValue, $t, formatCurrency, optionValueAsPriceId = false) => {
  if (!featureConfig) {
    return []
  }

  const mapOption = (option) => {
    if (option.type === 'group') {
      option.options = option.options.map(mapOption)
      return option
    }

    option.text = originalFeatureValue
    option.value = null

    if (option.price) {
      option.text = $t('c.subscribe-modal-v2.addon.text', {
        amount: option.quantity,
        unitPrice: formatCurrency(option.price.monthly_price * 12 / option.quantity / 100),
        monthlyPrice: formatCurrency(option.price.monthly_price / 100),
      })
      if (!optionValueAsPriceId) {
        option.value = option.addon_id
      } else {
        option.value = option.price.price_id
      }
    }

    return option
  }

  return featureConfig.options.map(mapOption)
}

/**
 * Iterates over all options in feature configuration. Flattens
 * the group / option hierarcy so that only real options will be
 * iterated
 */
export const iterateFeatureConfigOptions = (featureConfig, callback) => {
  const iterateOptions = (option) => {
    if (option.type === 'group') {
      option.options.forEach(iterateOptions)
      return
    }

    callback(option)
  }

  featureConfig.options.forEach(iterateOptions)
}

export const getPricelistAddonPrice = (addonId, addons, period) => {
  const addon = addons.find(addon => addon.addon_id === addonId)
  return addon?.prices?.find(price => price.period_unit === period)?.price_id
}
