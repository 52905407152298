import axios from 'axios'
import { NavigationFailureType } from 'vue-router/src/util/errors'
import sortBy from 'lodash.sortby'
import { clearState } from '~/utils/state'
import { ACCOUNT_PERSONAL, TEMPLATE_TYPE_USER } from '~/utils/constants'
import { getActiveAccount } from '~/utils/cookie'
import { populateRelationships } from '~/utils/api'
import { algoliaRecordToModel } from '~/utils/template'
import { omit } from '~/utils/object'

export const state = () => ({
  favourites: [],
  favouritesLoading: false,
  ownTemplates: [],
  categories: [],
  searchQuery: null,
  searchResults: [],
  searchLoading: false,
  templateCollections: [],
  templateCollectionsLoaded: false
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_CATEGORIES (state, categories) {
    state.categories = categories
  },
  SET_SEARCH_QUERY (state, query) {
    state.searchQuery = query
  },
  SET_SEARCH_RESULTS (state, results) {
    state.searchResults = results
  },
  SET_SEARCH_LOADING (state, isLoading) {
    state.searchLoading = isLoading
  },
  SET_TEMPLATE_COLLECTIONS (state, collections) {
    state.templateCollections = collections
    state.templateCollectionsLoaded = true
  },
  SET_OWN_TEMPLATES (state, templates) {
    state.ownTemplates = templates
  },
  DELETE_OWN_TEMPLATE (state, id) {
    state.ownTemplates = state.ownTemplates.filter(t => t.id !== id)
  },
  UPDATE_OWN_TEMPLATE (state, template) {
    state.ownTemplates = state.ownTemplates.map((t) => {
      if (t.id === template.id) { return template } else { return t }
    })
  },
  SET_FAVOURITES_LOADING (state, isLoading) {
    state.favouritesLoading = isLoading
  },
  SET_FAVOURITES (state, favourites) {
    state.favourites = favourites
    state.favouritesLoading = false
  },
  ADD_FAVOURITE (state, favourite) {
    state.favourites.push(favourite)
  },
  REMOVE_FAVOURITE (state, favouriteId) {
    state.favourites = state.favourites.filter(f => f.id !== favouriteId)
  },
}

export const actions = {
  async fetchCategories ({ commit }) {
    let categories = []

    const response = await this.$axios.$get('/categories')

    // Little hack for supporting new simplified category listing
    // where we have only one level categories. If we still have the old category
    // hierarchy we fallback to categorie under "company" main category
    // This can be removed after we have cleaned categories from backend.
    // TODO: Clean after backend returns only one level categories
    const parentCategoryCount = response.data.filter(c => !c.attributes.categoryId).length

    if (parentCategoryCount > 2) {
      // new category listing with only "parent categories"
      categories = response.data
    } else {
      // old category listing, fallback to categories on "company" parent category
      const parentCategory = response.data.find(c => ['yrityssopimukset', 'company-documents', 'dokument-for-foretag'].includes(c.attributes.slug))
      categories = response.data.filter(c => c.attributes.categoryId === parentCategory.id)
    }

    await commit('SET_CATEGORIES', categories)
  },

  async execQueryActions ({ commit, dispatch }, searchQuery) {
    await commit('SET_SEARCH_QUERY', searchQuery)

    // add length limit to narrow down results
    if (!searchQuery || searchQuery.length <= 2) {
      const params = new URLSearchParams({ ...this.$router.currentRoute.query })
      if (params.has('q')) {
        params.delete('q')
        window.history.replaceState(null, null, `?${params.toString()}`)
      }
      return
    }

    dispatch('fetchSearchResults', searchQuery)

    const params = new URLSearchParams(omit(['categoryId', 'collectionId'], { ...this.$router.currentRoute.query, q: encodeURIComponent(searchQuery) }))

    this.$router.replace({ path: `${this.$links.templates.search()}?${params.toString()}` }).catch((err) => {
      // ignore navigation duplicated error, which only happens in dev anyway
      if (err.type !== NavigationFailureType.duplicated) {
        this.$log.error(err)
      }
    })
  },

  async fetchSearchResults ({ commit, rootState, dispatch }, searchQuery) {
    // api keys might not be loaded when entering search page with direct url
    // and queryparams eg. /templates/search?q=testamentti
    if (!rootState.auth.algoliaApiKeysLoaded) {
      await dispatch('auth/fetchAlgoliaApiKeys', null, { root: true })
    }

    const appId = rootState.auth.algoliaApiKeys?.app_id
    const templatesApiKey = rootState.auth.algoliaApiKeys?.templates
    const userTemplatesApiKey = rootState.auth.algoliaApiKeys?.user_templates

    commit('SET_SEARCH_LOADING', true)

    const searchAction = (index, apiKey, templateType, queryParams = {}) => {
      return axios.post(`https://${appId}-dsn.algolia.net/1/indexes/${index}/query`, {
        params: new URLSearchParams(Object.assign(
          {},
          {
            query: searchQuery,
            hitsPerPage: 50
          },
          queryParams
        )).toString()
      }, {
        headers: {
          'X-Algolia-API-Key': apiKey,
          'X-Algolia-Application-Id': appId
        }
      }).then(res => res.data.hits.map(r => algoliaRecordToModel(r, templateType)))
    }

    // use chaining to continue code execution during search
    Promise.all([
      searchAction('templates', templatesApiKey),
      searchAction(
        'user_templates',
        userTemplatesApiKey,
        TEMPLATE_TYPE_USER,
        { hitsPerPage: 3, restrictSearchableAttributes: 'name' },
      )
    ])
      .then(([templates, userTemplates]) => {
        if (templates.length < userTemplates.length) {
          // make sure templates is at least same length as userTemplates
          templates = [
            ...templates,
            ...Array.from({ length: userTemplates.length - templates.length })]
        }

        const combinedResult = templates.reduce((acc, cur) => {
          if (userTemplates.length) {
            return acc.concat(userTemplates.shift(), cur)
          } else {
            return acc.concat(cur)
          }
        }, []).filter(Boolean)
        commit('SET_SEARCH_RESULTS', combinedResult)
      })
      .catch((err) => {
        commit('SET_SEARCH_RESULTS', [])
        this.$log.error(err)
      }).finally(() => {
        commit('SET_SEARCH_LOADING', false)
      })
  },

  async fetchTemplateCollections ({ commit }) {
    const activeAccount = getActiveAccount()

    const collections = await this.$axios.$get(
      activeAccount === ACCOUNT_PERSONAL
        ? '/me/templateCollections'
        : `/me/companies/${activeAccount}/templateCollections`
    ).then(r => r.data)

    if (!collections.length) {
      await commit('SET_TEMPLATE_COLLECTIONS', [])
      return
    }

    await commit('SET_TEMPLATE_COLLECTIONS', collections)
  },

  async fetchOwnTemplates ({ commit }) {
    const getTailoredTemplates = async () => {
      if (getActiveAccount() === ACCOUNT_PERSONAL) {
        return []
      }
      const templates = await this.$axios.$get('/me/templates?limit=999').then(populateRelationships).then(({ data }) => data)
      return templates
    }

    const getUserTemplates = async () => {
      const templates = await this.$axios.$get('/me/userTemplates?include=template&limit=999').then(populateRelationships).then(({ data }) => data)
      return templates
    }

    await Promise.all([
      getTailoredTemplates(),
      getUserTemplates()
    ]).then((values) => {
      const allTemplates = sortBy(
        values.reduce((acc, cur) => [...acc, ...cur], []),
        t => t.attributes.name
      )
      commit('SET_OWN_TEMPLATES', allTemplates)
    })
  },

  async deleteUserTemplate ({ commit }, template) {
    if (template.type !== TEMPLATE_TYPE_USER) {
      return
    }
    await this.$axios.$delete(`/me/userTemplates/${template.id}`)
    await commit('DELETE_OWN_TEMPLATE', template.id)
  },

  async updateUserTemplate ({ commit }, { template: { id, type }, name }) {
    if (type !== TEMPLATE_TYPE_USER) {
      return
    }
    const updatedTemplate = await this.$axios.$put(`/me/userTemplates/${id}?include=template`, { name })
      .then(populateRelationships)
      .then(({ data }) => data)
    await commit('UPDATE_OWN_TEMPLATE', updatedTemplate)
    return updatedTemplate
  },

  async fetchFavourites ({ commit }) {
    await commit('SET_FAVOURITES_LOADING', true)
    const favourites = await this.$axios.$get('/me/favourites?include=template&limit=999')
      .then(populateRelationships)
      .then(({ data }) => data)
    await commit('SET_FAVOURITES', favourites)

    return favourites
  },

  async addFavourite ({ commit }, { id, type }) {
    const data = { templateId: id, templateType: type }
    const favourite = await this.$axios.$post('/me/favourites?include=template', { data })
      .then(populateRelationships)
      .then(({ data }) => data)
    await commit('ADD_FAVOURITE', favourite)
  },

  async removeFavourite ({ state, commit }, { id: templateId, type: templateType }) {
    const favourite = state.favourites.find(
      ({ relationships: { template: { data: { id, type } } } }) => id === templateId && type === templateType
    )
    if (!favourite) { throw new Error('Favourite not found for template: ' + templateId) }

    await this.$axios.$delete(`/me/favourites/${favourite.id}`)
    await commit('REMOVE_FAVOURITE', favourite.id)
  },
}

export const getters = {
  isTemplateInFavourites: state => ({ id: templateId, type: templateType }) => {
    return !!state.favourites.find(
      ({ relationships: { template: { data: { id, type } } } }) => id === templateId && type === templateType
    )
  },
  favourites (state) {
    return sortBy(
      state.favourites
        .map(f => f?.relationships?.template?.data)
        .filter(x => !!x),
      f => f.attributes.name
    )
  },
  shouldLoadFavourites (state) {
    return !state.favourites.length && !state.favouritesLoading
  }
}
