import { clearState } from '~/utils/state'
import { LANGUAGE_EN } from '~/utils/constants'

export const state = () => ({
  loadingCount: 0,
  language: LANGUAGE_EN,
  cookieControlOpen: false,
  views: null,
  isViewsLoading: false,
})

export const mutations = {
  CLEAR: clearState(state()),
  INCREMENT_LOADING_COUNT (state) {
    state.loadingCount = state.loadingCount + 1
  },
  DECREMENT_LOADING_COUNT (state) {
    state.loadingCount = state.loadingCount - 1
  },
  SET_LANGUAGE (state, language) {
    state.language = language
  },
  SET_COOKIE_CONTROL_OPEN (state, open) {
    state.cookieControlOpen = open
  },
  SET_VIEWS (state, views) {
    state.views = views
  },
  SET_VIEWS_LOADING (state, isLoading) {
    state.isViewsLoading = isLoading
  },
}

export const actions = {
  async startLoading ({ commit }) {
    await commit('INCREMENT_LOADING_COUNT')
  },
  async stopLoading ({ commit }) {
    await commit('DECREMENT_LOADING_COUNT')
  },
  async setLanguage ({ commit }, language) {
    await commit('SET_LANGUAGE', language)
  },
  async clearAll ({ commit }) {
    await commit('archive/CLEAR')
    await commit('auth/CLEAR')
    await commit('builder/CLEAR')
    await commit('builderEdit/CLEAR')
    await commit('invite/CLEAR')
    await commit('login/CLEAR')
    await commit('notifications/CLEAR')
    await commit('settingsInvoices/CLEAR')
    await commit('subscribe/CLEAR')
    await commit('informationServices/CLEAR')
    await commit('settings/CLEAR')
    await commit('s/CLEAR')
    await commit('visibility/CLEAR')
    await commit('subscribe2/CLEAR')
  },
  async fetchViews ({ commit, state }) {
    if (state.isViewsLoading) {
      return
    }

    await commit('SET_VIEWS_LOADING', true)

    try {
      const views = await this.$axios.$get('/v2/views?per_page=100').then(r => r.data)
      await commit('SET_VIEWS', views ?? [])
    } catch (e) {
      this.$log.error(e)
    } finally {
      await commit('SET_VIEWS_LOADING', false)
    }
  },
}
