export const state = () => {
  return {
    isPreloadCalled: false,
    isOpen: false,
    requiredTemplateFeatures: []
  }
}

export const mutations = {
  SET_IS_PRELOAD_CALLED (state, value) {
    state.isPreloadCalled = value
  },
  SET_IS_OPEN (state, value) {
    state.isOpen = value
  },
  SET_REQUIRED_TEMPLATE_FEATURES (state, value) {
    state.requiredTemplateFeatures = value
  }
}

export const actions = {
  async setIsPreloadCalled ({ state, commit }, value) {
    if (state.isPreloadCalled && value) { return }

    await commit('SET_IS_PRELOAD_CALLED', value)
  },
  async updateIsOpen ({ commit, state }, value) {
    await commit('SET_IS_OPEN', value)

    if (!state.isPreloadCalled) {
      await commit('SET_IS_PRELOAD_CALLED', true)
    }
  },
  async setRequiredTemplateFeatures ({ commit }, features) {
    await commit('SET_REQUIRED_TEMPLATE_FEATURES', features)
  }
}
