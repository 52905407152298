import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export const pinKey = contractId => `s-pin-${contractId}`

/**
 * Converts ISO-8601 duration string into number of days.
 * Null if empty iso string.
 */
export const isoDurationToDays = (iso) => {
  if (!iso) {
    return null
  }

  return dayjs.duration(iso).asDays()
}

/**
 * Converts number of days from "Remind signees every X days"
 * into data object compatible with our v1 api.
 * Adds default remindEnd after 30 days.
 */
export const remindDaysToApi = (days) => {
  const data = {
    remindDuration: null,
    remindEnd: null,
  }

  if (!days) {
    return data
  }

  data.remindDuration = dayjs.duration(days, 'd').toISOString()
  data.remindEnd = dayjs().add(30, 'day').format() // ISO8601, without fraction seconds

  return data
}
