import { clearState } from '~/utils/state'

export const state = () => ({
  partner: null
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_PARTNER (state, partner) {
    state.partner = partner
  }
}

export const actions = {
}

export const getters = {
  logoUrl (state) {
    return state.partner ? state.partner.links.logoUrl : null
  }
}
