/* eslint-disable no-console */

export default function () {
  console.log(`
  ____
  |  _ \\  ___   ___ _   _  ___
  | | | |/ _ \\ / __| | | |/ _ \\
  | |_| | (_) | (__| |_| |  __/
  |____/ \\___/ \\___|\\__,_|\\___|

`)
}
