import Vue from 'vue'

Vue.mixin({
  methods: {
    stToastSuccess (message = null, title = null) {
      this.$bvToast.toast(message, {
        title,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        headerClass: title ? '' : 'd-none'
      })
    },
    stToastError (message = null, title = null) {
      this.$bvToast.toast(message, {
        title,
        variant: 'danger',
        solid: true,
        toaster: 'b-toaster-top-center',
        headerClass: title ? '' : 'd-none'
      })
    },
    stConfirm ({ message, cancelTitle, okVariant = 'red', okTitle, ...rest }) {
      const c = cancelTitle || this.$t('common.cancel')
      const o = okTitle || this.$t('common.confirm')

      return this.$DTMsgBox(
        Object.assign(
          {},
          { message, okTitle: o, cancelTitle: c, okVariant },
          rest
        )
      )
    },
    stConfirmOnlyOk ({ message, okVariant = 'default', okTitle, ...rest }) {
      return this.$DTMsgBox({ message, okVariant, okTitle, ...rest })
    },
    stConfirmDelete (message, options = {}) {
      return this.stConfirm({ message, ...Object.assign({}, { okTitle: this.$t('common.delete'), okVariant: 'red' }, options) })
    },
    stConfirmPrimary (message, okTitle, options = {}) {
      return this.stConfirm(Object.assign({
        message,
        okTitle,
        okVariant: 'blue'
      }, options))
    },
    stCustomConfirmModal (options, component) {
      return this.$DTMsgBox(options, component)
    }
  }
})
