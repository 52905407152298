import sortBy from 'lodash.sortby'
import { clearState } from '~/utils/state'
import { ACCOUNT_ITEM_META_TYPE_SYSTEM_CATEGORY, ACCOUNT_ITEM_META_ID_UNSORTED } from '~/utils/constants'
import { populateRelationships } from '~/utils/api'

export const state = () => ({
  usedMetas: [],
  usedMetasLoaded: false,
  usedMetasLoading: false,
  reminderClients: [],
  reminderClientsLoaded: false,
  openedItemId: null,
  unsortedCount: 0,
  unsortedCountLoaded: false,
  accountItemMetaTypes: [],
  accountItemMetaTypesLoaded: false,
  accountItemMetaTypesLoading: false
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_USED_METAS (state, usedMetas) {
    state.usedMetas = usedMetas
    state.usedMetasLoaded = true
  },
  SET_USED_METAS_LOADING (state, loading) {
    state.usedMetasLoading = loading
  },
  SET_REMINDER_CLIENTS (state, clients) {
    state.reminderClients = clients
    state.reminderClientsLoaded = true
  },
  SET_OPENED_ITEM_ID (state, id) {
    state.openedItemId = id
  },
  SET_UNSORTED_COUNT (state, count) {
    state.unsortedCount = count
    state.unsortedCountLoaded = true
  },
  SET_ACCOUNT_ITEM_META_TYPES (state, types) {
    state.accountItemMetaTypes = types
    state.accountItemMetaTypesLoaded = true
  },
  SET_ACCOUNT_ITEM_META_TYPES_LOADING (state, loading) {
    state.accountItemMetaTypesLoading = loading
  },
  ADD_ACCOUNT_ITEM_META_TYPE (state, type) {
    state.accountItemMetaTypes.push(type)
  },
  UPDATE_ACCOUNT_ITEM_META_TYPE (state, { id, title }) {
    state.accountItemMetaTypes = state.accountItemMetaTypes.map((type) => {
      if (type.id === id) {
        type.attributes.title = title
      }

      return type
    })
  },
  UPDATE_ACCOUNT_ITEM_META (state, { typeId, metaId, title }) {
    state.accountItemMetaTypes = state.accountItemMetaTypes.map((type) => {
      if (type.id === typeId) {
        type.relationships.accountItemMetas.data = type.relationships.accountItemMetas.data.map((m) => {
          if (m.id === metaId) {
            m.attributes.title = title
          }
          return m
        })
      }
      return type
    })
  },
  ADD_ACCOUNT_ITEM_META (state, meta) {
    state.accountItemMetaTypes.forEach((type) => {
      if (type.id === meta.attributes.accountItemMetaTypeId) {
        type.relationships.accountItemMetas.data.push(meta)
      }
    })
  },
  DELETE_ACCOUNT_ITEM_META_TYPE (state, typeId) {
    state.accountItemMetaTypes = state.accountItemMetaTypes.filter(t => t.id !== typeId)
  },
  DELETE_ACCOUNT_ITEM_META (state, { typeId, metaId }) {
    state.accountItemMetaTypes = state.accountItemMetaTypes.map((type) => {
      if (type.id === typeId) {
        type.relationships.accountItemMetas.data = type.relationships.accountItemMetas.data.filter(m => m.id !== metaId)
      }
      return type
    })
  }
}

export const actions = {
  async fetchUsedMetas ({ commit, state }) {
    if (state.usedMetasLoading) { return }

    await commit('SET_USED_METAS_LOADING', true)

    try {
      const response = await this.$axios.$get('/me/account/accountItemMetas?onlyInUse=true')
      await commit('SET_USED_METAS', response.data)
      await commit('SET_USED_METAS_LOADING', false)

      return response.data
    } catch (e) {
      await commit('SET_USED_METAS_LOADING', false)
      throw e
    }
  },
  async fetchUsedTypes ({ dispatch }) {
    await dispatch('fetchUsedMetas')
    await dispatch('archive/fetchAccountItemMetaTypes', null, { root: true })
  },
  async fetchReminderClients ({ commit }) {
    const response = await this.$axios.$get('/me/reminders/clients')
    await commit('SET_REMINDER_CLIENTS', response.data)
  },
  async setOpenedItemId ({ commit }, id) {
    await commit('SET_OPENED_ITEM_ID', id)
  },
  async fetchUnsortedCount ({ commit }, id) {
    const response = await this.$axios.$get(`/me/account/items?&accountItemMetaId=${ACCOUNT_ITEM_META_ID_UNSORTED}&limit=1`)
    await commit('SET_UNSORTED_COUNT', response.meta.pagination.total)
  },
  async fetchAccountItemMetaTypes ({ commit, state }) {
    if (state.accountItemMetaTypesLoading) { return }
    await commit('SET_ACCOUNT_ITEM_META_TYPES_LOADING', true)

    try {
      const typesResponse = await this.$axios.$get('/me/account/accountItemMetaTypes').then(populateRelationships)
      const metasResponse = await this.$axios.$get('/me/account/accountItemMetas').then(populateRelationships)

      // Populate metas for every type
      typesResponse.data.forEach((type) => {
        type.relationships = {
          accountItemMetas: {
            data: []
          }
        }

        metasResponse.data.forEach((meta) => {
          if (meta.attributes.accountItemMetaTypeId === type.id) {
            type.relationships.accountItemMetas.data.push(meta)
          }
        })
      })

      await commit('SET_ACCOUNT_ITEM_META_TYPES', typesResponse.data)
      await commit('SET_ACCOUNT_ITEM_META_TYPES_LOADING', false)
    } catch (e) {
      await commit('SET_ACCOUNT_ITEM_META_TYPES_LOADING', false)
      throw e
    }
  },
  async createAccountItemMeta ({ commit }, { typeId, title }) {
    const response = await this.$axios.$post('/me/account/accountItemMetas', {
      data: {
        attributes: { title },
        relationships: {
          accountItemMetaType: {
            type: 'accountItemMetaType',
            id: typeId
          }
        }
      }
    }).then(populateRelationships)
    await commit('ADD_ACCOUNT_ITEM_META', response.data)
    return response.data
  },
  async updateAccountItemMeta ({ commit }, { typeId, metaId, title }) {
    const response = await this.$axios.$put(`/me/account/accountItemMetas/${metaId}`, {
      data: {
        id: metaId,
        attributes: { title }
      }
    })
    await commit('UPDATE_ACCOUNT_ITEM_META', { typeId, metaId, title })
    return response.data
  },
  async deleteAccountItemMeta ({ commit }, { typeId, metaId }) {
    await this.$axios.$delete(`/me/account/accountItemMetas/${metaId}`)
    await commit('DELETE_ACCOUNT_ITEM_META', { typeId, metaId })
  },
  async createAccountItemMetaType ({ commit }, title) {
    const response = await this.$axios.$post('/me/account/accountItemMetaTypes?include=accountItemMetas', { data: { attributes: { title } } }).then(populateRelationships)
    await commit('ADD_ACCOUNT_ITEM_META_TYPE', response.data)
    return response.data
  },
  async updateAccountItemMetaType ({ commit }, { id, title }) {
    await this.$axios.$put(`/me/account/accountItemMetaTypes/${id}`, {
      data: {
        id,
        attributes: { title }
      }
    })
    await commit('UPDATE_ACCOUNT_ITEM_META_TYPE', {
      id,
      title
    })
  },
  async deleteAccountItemMetaType ({ commit }, { typeId }) {
    await this.$axios.$delete(`/me/account/accountItemMetaTypes/${typeId}`)
    await commit('DELETE_ACCOUNT_ITEM_META_TYPE', typeId)
  }
}

export const getters = {
  usedTypes (state) {
    const types = state.accountItemMetaTypes
    const usedMetaIds = state.usedMetas.map(meta => meta.id)

    // Return empty array if types not loaded yet
    if (types.length === 0) { return [] }

    // Sort alphabetically and category type first
    const category = types.find(t => t.attributes.type === ACCOUNT_ITEM_META_TYPE_SYSTEM_CATEGORY)
    const typesWithoutCategory = sortBy(types.filter(t => t.attributes.type !== ACCOUNT_ITEM_META_TYPE_SYSTEM_CATEGORY), t => t.attributes.title)

    if (category) {
      typesWithoutCategory.unshift(category)
    }

    // Filter out metas which are not in use
    const mappedTypes = typesWithoutCategory
      .map((type) => {
        const metas = sortBy(type.relationships.accountItemMetas.data.filter(meta => usedMetaIds.includes(meta.id)), m => m.attributes.title)
        return {
          id: type.id,
          attributes: {
            title: type.attributes.title,
            type: type.attributes.type
          },
          metas
        }
      })

    return mappedTypes.filter(t => t.metas.length > 0)
  },
  getAccountItemMetaById: state => (id) => {
    for (let typeIndex = 0; typeIndex < state.accountItemMetaTypes.length; typeIndex++) {
      const type = state.accountItemMetaTypes[typeIndex]
      for (let metaIndex = 0; metaIndex < type.relationships.accountItemMetas.data.length; metaIndex++) {
        const meta = type.relationships.accountItemMetas.data[metaIndex]
        if (meta.id === id) { return meta }
      }
    }

    return null
  }
}
