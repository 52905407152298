import {
  setReturn,
  setTargetAccount
} from '~/utils/return'

export default function ({ query }) {
  if (query.return) {
    setReturn(query.return)
  }

  if (query.account) {
    setTargetAccount(query.account)
  }
}
