
export default {
  data () {
    return {
      isLoading: true,
    }
  },
  computed: {
    requiredTemplateFeatures () {
      return this.$store.state.subscription.requiredTemplateFeatures ?? []
    },
    subwindowUrl () {
      const url = new URL('/2/subscription', window.location.origin)
      url.searchParams.append('is-child-app', 'true')
      if (this.requiredTemplateFeatures.length) {
        url.searchParams.append('template-features', this.requiredTemplateFeatures)
      }

      return url.toString().replace(window.location.origin, '')
    },
  },
  mounted () {
    this.$refs.frame.addEventListener('load', this.onFrameLoad)
  },
  beforeDestroy () {
    this.$refs.frame.removeEventListener('load', this.onFrameLoad)
  },
  methods: {
    onFrameLoad () {
      this.isLoading = false
    },
  }
}
