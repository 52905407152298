
import dayjs from 'dayjs'
import Cookie from 'js-cookie'

export default {
  data () {
    return {
      // Defaults true to prevent flickering
      consentGiven: true,

      configure: false,
      ad: true,
      analytics: true,
      personalization: true,
      functionality: true,
      security: true,
      disable: this.$route.query.disable_cookie_banner === 'true'
    }
  },
  computed: {
    isStoryblok () {
      return !!this.$route.query._storyblok
    },
    visible () {
      return (!this.consentGiven && !this.isStoryblok && !this.disable) || this.cookieControlOpen
    },
    cookieControlOpen () {
      return this.$store.state.cookieControlOpen
    }
  },
  watch: {
    cookieControlOpen (o) {
      // When dialog opened again, lets
      // open the configure view immediately
      if (o) {
        this.configure = true
      }
    }
  },
  mounted () {
    this.updateConsentGiven()

    try {
      const cookies = JSON.parse(Cookie.get('CookieConsent'))
      if (cookies) {
        this.ad = cookies.ad ?? this.ad
        this.analytics = cookies.analytics ?? this.analytics
        this.personalization = cookies.personalization ?? this.personalization
      }
    } catch (e) {}
  },
  methods: {
    openConfigure () {
      this.configure = true

      // Make optional cookies disabled by default
      this.ad = false
      this.analytics = false
      this.personalization = false
    },
    accept () {
      this.$store.commit('SET_COOKIE_CONTROL_OPEN', false)

      this.setCookie({
        ad: this.ad,
        analytics: this.analytics,
        personalization: this.personalization,
        functionality: true,
        security: true
      })
      this.$gtm.push({
        event: 'update_cookie_consent'
      })
      if (this.analytics) {
        this.$ph.enable()
      }
    },
    setCookie (data) {
      const options = {
        expires: dayjs().add(1, 'year').toDate(),
        path: '/',
      }

      const domain = process.env.COOKIE_DOMAIN
      if (domain && location.host.includes(domain)) {
        options.domain = domain
      }

      Cookie.set('CookieConsent', JSON.stringify(data), options)
      this.updateConsentGiven()
    },
    updateConsentGiven () {
      this.consentGiven = !!Cookie.get('CookieConsent')
    }
  }
}
