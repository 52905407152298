import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.starts-with.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { ALL_LANGUAGES, LANGUAGE_EN } from '~/utils/constants';
import { getCountry } from '~/utils/cookie';
import { getLanguage, setLanguage } from '~/utils/language';
Vue.use(VueI18n);
var DEFAULT_LANGUAGE = LANGUAGE_EN;
export default (function (_ref, inject) {
  var app = _ref.app,
    query = _ref.query;
  // Detect browser language and use that as language if matches
  var browserLanguage = ALL_LANGUAGES.find(function (language) {
    return navigator.language.startsWith(language);
  });
  var queryLanguage = ALL_LANGUAGES.find(function (language) {
    return language === query.language;
  });
  var cookieLanguage = ALL_LANGUAGES.find(function (language) {
    return getCountry('').startsWith(language);
  });

  // Load default locale from localStorage
  var language = getLanguage(queryLanguage || cookieLanguage || browserLanguage || DEFAULT_LANGUAGE);

  // Set i18n instance on app. This way we can use
  // it in middleware and pages asyncData/fetch
  var i18n = new VueI18n({
    locale: language,
    fallbackLocale: DEFAULT_LANGUAGE,
    dateTimeFormats: {
      en: {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      'en-GB': {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      fi: {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      de: {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      },
      sv: {
        time: {
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        long: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }
      }
    },
    // TODO: we should get rid of this configuration and use i18n-n component instead as in SubscribeModalPlanCard
    numberFormats: {
      en: {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'fi-PL': {
        currency: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 2
        }
      },
      'en-PL': {
        currency: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 2
        }
      },
      'sv-PL': {
        currency: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 2
        }
      },
      'de-PL': {
        currency: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'PLN',
          minimumFractionDigits: 2
        }
      },
      'fi-FI': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'en-FI': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'sv-FI': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'de-FI': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'fi-SE': {
        currency: {
          style: 'currency',
          currency: 'SEK',
          currencyDisplay: 'symbol',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        }
      },
      'en-SE': {
        currency: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        }
      },
      'sv-SE': {
        currency: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        }
      },
      'de-SE': {
        currency: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        }
      },
      'fi-GB': {
        currency: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2
        }
      },
      'en-GB': {
        currency: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2
        }
      },
      'sv-GB': {
        currency: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2
        }
      },
      'de-GB': {
        currency: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2
        }
      },
      'fi-DE': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'en-DE': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'sv-DE': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'de-DE': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      }
    },
    messages: {
      fi: {
        common: require('~/locales/fi/common.json'),
        p: require('~/locales/fi/pages.json'),
        c: require('~/locales/fi/components.json')
      },
      en: {
        common: require('~/locales/en/common.json'),
        p: require('~/locales/en/pages.json'),
        c: require('~/locales/en/components.json')
      },
      sv: {
        common: require('~/locales/sv/common.json'),
        c: require('~/locales/sv/components.json'),
        p: require('~/locales/sv/pages.json')
      },
      de: {
        common: require('~/locales/de/common.json'),
        c: require('~/locales/de/components.json'),
        p: require('~/locales/de/pages.json')
      }
    }
  });
  app.i18n = i18n;

  // Listen for store changes and update i18n language
  // and persist the language to the localStorage
  app.store.watch(function (state) {
    return state.language;
  }, function (l) {
    setLanguage(l);
    i18n.locale = l;
  });

  // Set initial locale
  app.store.dispatch('setLanguage', language);
});