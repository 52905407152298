import { clearState } from '~/utils/state'
import { pinKey } from '~/utils/sign'

export const state = () => ({
  initialData: [],
  contractId: null,
  permissionId: null,
  logId: null,
  secret: null,
  pin: null,
  details: null
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_INITIAL_DATA (state, initialData) {
    state.initialData = initialData
  },
  SET_CONTRACT_ID (state, contractId) {
    state.contractId = contractId
  },
  SET_PERMISSION_ID (state, permissionId) {
    state.permissionId = permissionId
  },
  SET_LOG_ID (state, logId) {
    state.logId = logId
  },
  SET_LOG_SECRET (state, secret) {
    state.secret = secret
  },
  SET_PIN (state, pin) {
    state.pin = pin
  },
  SET_DETAILS (state, details) {
    state.details = details
  }
}

export const actions = {
  async init ({ commit, dispatch, rootState }, { contractId, permissionId, logId, pin, secret }) {
    if (!contractId) {
      throw new Error('No contract id provided')
    }

    const initialData = await this.$axios.$get(`/contract-signature/${contractId}/${permissionId}`)
    await commit('SET_INITIAL_DATA', initialData)

    await commit('SET_CONTRACT_ID', contractId)
    await commit('SET_PERMISSION_ID', permissionId)
    await commit('SET_LOG_ID', logId)

    // Not every signing url has secret
    if (secret) {
      await commit('SET_LOG_SECRET', secret)
    }

    // If no client, set UI language from signature
    if (!rootState.auth.client) {
      await dispatch('setLanguage', initialData.signature.defaultLanguage, { root: true })
    }

    // Load pin if stored in sessionStorage
    const storedPin = pin || sessionStorage.getItem(pinKey(contractId))

    // If PIN available try to login
    await dispatch('login', storedPin)
  },
  async login ({ state, commit }, pin = null) {
    if (pin) {
      try {
        const details = await this.$axios.$get(`/contract-signature/${state.contractId}/${state.permissionId}/details`, {
          headers: {
            'x-pin': pin
          }
        })

        await commit('SET_PIN', pin)
        await commit('SET_DETAILS', details)

        // Save correct pin to sessionStorage
        sessionStorage.setItem(pinKey(state.contractId), state.pin)
        return true
      } catch (e) {
        this.$log.error(e)
      }
    }

    // Clear pin and details if no return before this
    await commit('SET_PIN', null)
    await commit('SET_DETAILS', null)

    return false
  },
  async fetchDetails ({ state, commit }) {
    const details = await this.$axios.$get(`/contract-signature/${state.contractId}/${state.permissionId}/details`, {
      headers: {
        'x-pin': state.pin
      }
    })

    await commit('SET_DETAILS', details)
  }
}

export const getters = {
}
