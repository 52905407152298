import Cookie from 'js-cookie'
import {
  STAGE_DEVELOPMENT
} from '~/utils/constants'

// Tests don't have access to window-object, use a local URL to mock something out
let currentHost = process?.browser ? window.location.host : 'app.sopimustieto.test'

// No need to pass domain in case of localhost
if (process.env.STAGE === STAGE_DEVELOPMENT && currentHost.includes('localhost')) {
  currentHost = undefined
}

const options = {
  expires: 7,
  secure: process.env.STAGE !== STAGE_DEVELOPMENT || window.location.protocol === 'https:',
  sameSite: 'Strict',
  domain: process.env.COOKIE_DOMAIN || currentHost,
}

export const setApiToken = token => Cookie.set('api_token', token, options)
export const setActiveAccount = account => Cookie.set('active_account', account, options)

export const removeApiToken = () => Cookie.remove('api_token', options)
export const removeActiveAccount = () => Cookie.remove('active_account', options)

export const getApiToken = () => Cookie.get('api_token')
export const getActiveAccount = () => Cookie.get('active_account')

export const getCountry = defaultValue => Cookie.get('docue_country') || defaultValue
export const setCountry = value => Cookie.set('docue_country', value, options)
