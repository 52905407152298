import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import sortBy from 'lodash.sortby';
export default (function (_ref, inject) {
  var store = _ref.store;
  var countries, locale, clientLang;
  var initCountries = function initCountries() {
    var _client$attributes;
    var client = store.state.auth.client;
    // check to prevent unnecessary re-requires
    if (countries && locale && clientLang === (client === null || client === void 0 ? void 0 : (_client$attributes = client.attributes) === null || _client$attributes === void 0 ? void 0 : _client$attributes.language)) {
      return {
        countries: countries,
        targetLang: clientLang
      };
    }
    clientLang = client.attributes.language;
    countries = require('i18n-iso-countries');
    locale = require("i18n-iso-countries/langs/".concat(clientLang, ".json"));
    countries.registerLocale(locale);
    return {
      countries: countries,
      targetLang: clientLang
    };
  };
  var getCountryNameByCode = function getCountryNameByCode(code) {
    var _initCountries = initCountries(),
      countries = _initCountries.countries,
      targetLang = _initCountries.targetLang;
    return countries.getName(code, targetLang);
  };
  var getAllCountryOptions = function getAllCountryOptions() {
    var _initCountries2 = initCountries(),
      countries = _initCountries2.countries,
      targetLang = _initCountries2.targetLang;
    return sortBy(Object.keys(countries.getAlpha2Codes()).map(function (value) {
      return {
        text: countries.getName(value, targetLang),
        value: value
      };
    }), function (value) {
      return value.text;
    });
  };
  inject('country', {
    getAllCountryOptions: getAllCountryOptions,
    getCountryNameByCode: getCountryNameByCode
  });
});