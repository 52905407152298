import { TEMPLATE_TYPE_DEFAULT } from './constants'

/**
 * Returns boolean indicating is the current plan eligible
 * based on template data
 *
 * ok if no plans at all
 * ok if no disabled plans
 * ok if disabled plans does not contain the provided plan
 */
export const isEligiblePlan = (template, plan) => {
  const disabledPlans = template.attributes.disableInPlans
  const isEligible = !disabledPlans ||
    disabledPlans.length === 0 ||
    !disabledPlans.includes(plan)

  return isEligible
}

/**
 * Returns if the current account type is ok for the template
 */
export const isEligibleAccountType = (template, accountType) => {
  const types = template.attributes.clientTypes
  const isEligible = !types ||
    types.includes(accountType)

  return isEligible
}

/**
 *  Returns model object based on data in template Algolia record
 */
export const algoliaRecordToModel = (record, type = TEMPLATE_TYPE_DEFAULT) => {
  return {
    id: record.id,
    type,
    attributes: {
      name: record.name,
      disableInPlans: record.disable_in_plans || [],
      clientTypes: record.client_types,
      shortDescription: record.short_description,
      state: record.state,
      visibility: record.visibility,
      language: record.language,
      type: record?.type
    }
  }
}

/**
 * Returns name for the new contract based on template model
 *
 * @param {*} template
 */
export const getNewContractName = (template) => {
  return template.attributes.contractName || template.attributes.name
}
