export class Cache {
  constructor (namespace, storage = window.sessionStorage) {
    this.namespace = namespace
    this.storage = storage
  }

  /**
   * Returns value from the cache. Returns defaultValue if no cached item available or expired.
   * @param {string} key
   * @param {*} defaultValue
   */
  get (key, defaultValue = null) {
    return this._getValue(key) || defaultValue
  }

  /**
   * Sets cache value which expires after expiresS seconds. Defaults to no expiry.
   * @param {string} key
   * @param {*} value
   * @param {int} expiresS
   */
  set (key, value, expiresS = 0) {
    const expires = new Date()
    expires.setSeconds(expires.getSeconds() + expiresS)
    const item = {
      value,
      expires: expiresS > 0 ? expires : null
    }
    this._setItem(key, item)
  }

  /**
   * Updates item value but not updating the expiry date. If no initial value, creates new one
   * with provided expiry date.
   * @param {string} key
   * @param {*} value
   * @param {int} expiresS
   */
  update (key, value, expiresS = 0) {
    const item = this._getItem(key)
    if (item) {
      item.value = value
      this._setItem(key, item)
    } else {
      this.set(key, value, expiresS)
    }
  }

  clear () {
    Object.keys(this.storage).filter(key => key.startsWith(this.namespace)).forEach(key => this.storage.removeItem(key))
  }

  _getValue (key) {
    const item = this._getItem(key)
    if (item) {
      return item.value
    }

    return undefined
  }

  _setItem (key, item) {
    this.storage.setItem(this._getKey(key), JSON.stringify(item))
  }

  _getItem (key) {
    const item = this.storage.getItem(this._getKey(key))
    if (item) {
      const parsed = JSON.parse(item)

      // If no expiry date, return value
      if (!parsed.expires) { return parsed }

      // If expiry date exists, check that it is not expired
      parsed.expires = new Date(parsed.expires)
      if (parsed.expires > new Date()) {
        return parsed
      }
    }

    return undefined
  }

  _getKey (key) {
    return `${this.namespace}-${key}`
  }
}

export class TestStorage {
  constructor (debug = false) {
    this.values = {}
    this.debug = debug
  }

  setItem (key, value) {
    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(`[TestStorage] setItem: ${key}: ${value}`)
    }

    this.values[key] = value
  }

  getItem (key) {
    const value = this.values[key]

    if (this.debug) {
      // eslint-disable-next-line no-console
      console.log(`[TestStorage] getItem: ${key}: ${value}`)
    }

    return value
  }
}
