import { v4 as uuid } from 'uuid'
import Vue from 'vue'

/**
 *
 * DEPRECATED! Use toaster plugin instead!
 *
 */

Vue.mixin({
  methods: {
    showSuccess (message = undefined, title = undefined) {
      this.$bvToast.toast(message, {
        title,
        variant: 'success',
        solid: true,
        noCloseButton: true,
        toaster: 'b-toaster-top-center'
      })
    },
    showError (message = undefined, title = undefined) {
      const m = message || this.$t('common.unknown-error')
      const t = title || this.$t('common.error')

      this.$bvToast.toast(m, {
        title: t,
        variant: 'danger',
        solid: true,
        noCloseButton: true,
        toaster: 'b-toaster-top-center'
      })
    },
    showLoadingMessage (message) {
      const id = uuid()

      this.$bvToast.toast(message, {
        id,
        noCloseButton: true,
        noAutoHide: true,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center'
      })

      return id
    },
    hideLoadingMessage (id) {
      this.$bvToast.hide(id)
    }
  }
})
