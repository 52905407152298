import { ALL_LANGUAGES } from '~/utils/constants'

const STORAGE_KEY = 'locale'

export const isSupportedLanguage = language => ALL_LANGUAGES.includes(language)

export const setLanguage = (language) => {
  if (isSupportedLanguage(language)) {
    localStorage.setItem(STORAGE_KEY, language)
  }
}

export const getLanguage = defaultValue => localStorage.getItem(STORAGE_KEY) || defaultValue
