import { clearState } from '~/utils/state'

export const state = () => ({
  products: [],
  productsLoaded: false,
  productsLoading: false
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_PRODUCTS (state, products) {
    state.products = products
    state.productsLoaded = true
  },
  SET_PRODUCTS_LOADING (state, loading) {
    state.productsLoading = loading
  }
}

export const actions = {
  async fetchProducts ({ commit, state }) {
    if (state.productsLoading) { return }

    await commit('SET_PRODUCTS_LOADING', true)
    const response = await this.$axios.$get('/specks/types')
    await commit('SET_PRODUCTS', response.data)
    await commit('SET_PRODUCTS_LOADING', false)
  }
}

export const getters = {
}
