import Cookie from 'js-cookie'

import {
  STAGE_PRODUCTION
} from '~/utils/constants'

const cookieOptions = {
  path: '/',
  domain: process.env.COOKIE_DOMAIN
}

// Get nodejs version of URL for unit tests
const URL = typeof window === 'undefined' ? require('url').URL : window.URL

/**
 * Returns boolean value indicating if it is safe to redirect to that url.
 * Main reason is to check that ?return parameter cannot be used for malicious use.
 *
 * @param {string} url
 */
export const isSafe = (url) => {
  try {
    const u = new URL(url)
    const domain = u.hostname.split('.')
    const base = domain[domain.length - 2]
    const end = domain[domain.length - 1]

    return (base === 'sopti' && end === 'io') ||
      (base === 'eucod' && end === 'io') ||
      (base === 'sopimustieto' && end === 'fi') ||
      (base === 'docue' && end === 'com') ||
      (process.env.STAGE !== STAGE_PRODUCTION && (end === 'localhost' || end === 'test'))
  } catch (e) {
    return false
  }
}

/**
 * Sets return parameter to cookies if it safe url
 *
 * @param {string} url
 */
export const setReturn = (url, prefix = '') => isSafe(url)
  ? Cookie.set(prefix + 'return', url, cookieOptions)
  : Cookie.remove(prefix + 'return', cookieOptions)

/**
 * Returns return target from cookies.
 * Returns null if not found (for example the url was not safe)
 */
export const getReturn = (prefix = '') => {
  const target = Cookie.get(prefix + 'return')
  if (isSafe(target)) {
    return target
  }

  return null
}

/**
 * Clears return parameter from cookies
 */
export const clearReturn = (prefix = '') => Cookie.remove(prefix + 'return', cookieOptions)

/**
 * Returns return parameter from cookies and clears it after that
 */
export const popReturn = (prefix = '') => {
  const target = getReturn(prefix)
  clearReturn(prefix)

  return target
}

/**
 * Sets target account to cookies
 *
 * @param {string} account
 */
export const setTargetAccount = (account, prefix = '') => Cookie.set(prefix + 'targetAccount', account, cookieOptions)

/**
 * Returns target account from cookies
 */
export const getTargetAccount = (prefix = '') => Cookie.get(prefix + 'targetAccount')

/**
 * Clears target account from cookies
 */
export const clearTargetAccount = (prefix = '') => Cookie.remove(prefix + 'targetAccount', cookieOptions)

/**
 * Returns target account from cookies and clears it
 */
export const popTargetAccount = (prefix = '') => {
  const account = getTargetAccount(prefix)
  clearTargetAccount(prefix)

  return account
}
