import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import { clearState } from '~/utils/state'

export const state = () => ({
  itemValuesByName: {},
  visibleItems: [],
  visibleGroups: []
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_INITIAL_ITEM_VALUES (state, groups) {
    const values = {}

    groups.forEach((group) => {
      group.items.forEach((item) => {
        values[item.name] = cloneDeep(item.value)
      })
    })

    state.itemValuesByName = values
  },
  SET_ITEM_VALUE (state, { value, name }) {
    state.itemValuesByName[name] = cloneDeep(value)
  },
  SET_ITEM_VISIBILITY (state, { name, visibility }) {
    const index = state.visibleItems.indexOf(name)
    if (visibility) {
      if (index === -1) { state.visibleItems.push(name) }
    } else if (index !== -1) { state.visibleItems.splice(index, 1) }
  },
  SET_GROUP_VISIBILITY (state, { hash, visibility }) {
    const index = state.visibleGroups.indexOf(hash)
    if (visibility) {
      if (index === -1) { state.visibleGroups.push(hash) }
    } else if (index !== -1) { state.visibleGroups.splice(index, 1) }
  }
}

export const actions = {
  setInitialItemValues ({ state, commit, dispatch }, groups) {
    commit('SET_INITIAL_ITEM_VALUES', groups)
    updateVisibility(state, commit, groups)
  },
  updateItemValue ({ commit, dispatch }, { name, value, groups }) {
    commit('SET_ITEM_VALUE', {
      name,
      value
    })
    dispatch('updateVisibility', { groups })
  },
  updateVisibility: debounce(({ state, commit }, { groups }) => {
    updateVisibility(state, commit, groups)
  }, 300)
}

export const getters = {
}

function updateVisibility (state, commit, groups) {
  groups.forEach((group) => {
    let hideGroup = true

    group.items.forEach((item) => {
      let show = true
      const andGroups = item.couplings ? item.couplings.split('&') : []
      andGroups.forEach((andGroup) => {
        const orGroups = andGroup.split(',')
        let andGroupPasses = false

        orGroups.forEach((orGroup) => {
          /* eslint complexity: 0 */
          const parts = orGroup.split(':')
          let orGroupPasses = false

          let relationName = parts[0]
          let isNegative = false
          if (relationName.substr(0, 1) === '!') {
            relationName = relationName.substr(1)
            isNegative = true
          }

          const relationCurrentValue = state.itemValuesByName[relationName]
          const isRelationShown = state.visibleItems.includes(relationName)

          if (parts.length === 1) {
            if (relationCurrentValue !== null && relationCurrentValue !== undefined && relationCurrentValue.length && isRelationShown) {
              orGroupPasses = true
            }
          } else if (parts.length === 2) {
            const relationValue = parts[1]

            if (Array.isArray(relationCurrentValue)) {
              relationCurrentValue.forEach((item) => {
                if (item === relationValue && isRelationShown) {
                  orGroupPasses = true
                }
              })
            } else if (relationCurrentValue === relationValue && isRelationShown) {
              orGroupPasses = true
            }
          }

          if ((!isNegative && orGroupPasses) || (isNegative && !orGroupPasses)) {
            andGroupPasses = true
          }
        })

        if (!andGroupPasses) {
          show = false
          return false
        }
      })

      commit('SET_ITEM_VISIBILITY', {
        name: item.name,
        visibility: show
      })

      if (show) {
        hideGroup = false
      }
    })

    commit('SET_GROUP_VISIBILITY', {
      hash: group.hash,
      visibility: !hideGroup
    })
  })
}
