import get from 'lodash.get'
import Vue from 'vue'

export default function ({ app, store, $_nuxtApp: nuxtApp }) {
  /**
   * !HACK: nuxt-bugsnag is not compatible with nuxt-bridge so we have to pass
   * the overridden vue error handler to nuxt app's instance of vue config
   * because nuxt-bridge uses that to overwrite the error handler
   *
   * @see https://github.com/nuxt/bridge/blob/ba491a76fbcdba7850c10f6b091052c6409d583b/packages/bridge/src/runtime/app.plugin.mjs#L102
   */
  nuxtApp.vueApp.config.errorHandler = Vue.config.errorHandler

  /**
   * Do not report nuxt errors which are happened during update. The app
   * will handle this situation so there is no need to report these.
   */
  app.$bugsnag.addOnError((event) => {
    // add user information to the error
    const client = store.state.auth.client
    const company = store.getters['auth/company']
    if (client) {
      event.setUser(client.id, client.attributes.email)

      if (company) {
        event.addMetadata('company', {
          id: company.id,
          displayNane: company.attributes.displayName
        })
      }
    }

    const ingored = [
      'expected expression, got \'<\'',
      'Unexpected token \'<\'',
      'ERR_REDIRECT', // nuxt uses this internally when calling redirect(), this is NOT an actual error
    ]

    const message = get(event, 'errors.0.errorMessage', '')

    const isIgnored = ingored.reduce((result, ignored) => {
      return message.includes(ignored) || result
    }, false)

    return !isIgnored
  })
}
