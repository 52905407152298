import "core-js/modules/es.object.to-string.js";
export default function (_ref, inject) {
  var store = _ref.store;
  inject('chargebee', {
    init: function init() {
      if (window.Chargebee) {
        return Promise.resolve(window.Chargebee);
      }
      var script = document.createElement('script');
      script.src = 'https://js.chargebee.com/v2/chargebee.js';
      script.async = true;
      document.body.appendChild(script);
      return new Promise(function (resolve, reject) {
        script.onload = function () {
          var _store$getters$authC, _store$getters$authC2;
          window.Chargebee.init({
            site: (_store$getters$authC = store.getters['auth/config']) === null || _store$getters$authC === void 0 ? void 0 : _store$getters$authC.chargebee_site,
            publishableKey: (_store$getters$authC2 = store.getters['auth/config']) === null || _store$getters$authC2 === void 0 ? void 0 : _store$getters$authC2.chargebee_publishable_key
          });
          resolve(window.Chargebee);
        };
        script.onerror = reject;
      });
    }
  });
}