import { clearState } from '~/utils/state'

export const state = () => ({
  invitation: null,
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_INVITATION (state, invitation) {
    state.invitation = invitation
  },
}

export const actions = {
  async fetchInvitation ({ commit }, invitationId) {
    const response = await this.$axios.$get(`/companyInvites/${invitationId}`)
    await commit('SET_INVITATION', response.data)
  }
}
