import { PAYMENT_METHOD_CARD, PERIOD_YEAR, PRODUCT_TYPE_GENERAL } from '~/utils/constants'
import { clearState } from '~/utils/state'

export const state = () => {
  return {
    selectedPeriod: PERIOD_YEAR,
    selectedProductType: PRODUCT_TYPE_GENERAL,
    selectedPlan: null,
    planData: null,
    paymentMethod: PAYMENT_METHOD_CARD,
    billingCompanyName: '',
    billingCompanyIdent: '',
    billingVatNumber: '',
    einvoiceAccount: '',
    einvoiceOperator: '',
    billingAddress: '',
    billingCity: '',
    billingPostCode: '',
    coupon: '',
    isCompany: true,
    activeCoupon: null,
    estimate: null,
    vatNumberFieldEnabled: false,
  }
}

export const mutations = {
  CLEAR: clearState(state()),
  SET_SELECTED_PERIOD (state, period) {
    state.selectedPeriod = period
  },
  SET_SELECTED_PRODUCT_TYPE (state, type) {
    state.selectedProductType = type
  },
  SET_PLAN_DATA (state, data) {
    state.planData = data
  },
  SET_SELECTED_PLAN (state, plan) {
    state.selectedPlan = plan
  },
  SET_PAYMENT_METHOD (state, value) {
    state.paymentMethod = value
  },
  SET_BILLING_COMPANY_NAME (state, value) {
    state.billingCompanyName = value
  },
  SET_BILLING_COMPANY_IDENT (state, value) {
    state.billingCompanyIdent = value
  },
  SET_BILLING_VAT_NUMBER (state, value) {
    state.billingVatNumber = value
  },
  SET_EINVOICE_ACCOUNT (state, value) {
    state.einvoiceAccount = value
  },
  SET_EINVOICE_OPERATOR (state, value) {
    state.einvoiceOperator = value
  },
  SET_BILLING_ADDRESS (state, value) {
    state.billingAddress = value
  },
  SET_BILLING_CITY (state, value) {
    state.billingCity = value
  },
  SET_BILLING_POST_CODE (state, value) {
    state.billingPostCode = value
  },
  SET_COUPON (state, value) {
    state.coupon = value
  },
  SET_ACTIVE_COUPON (state, value) {
    state.activeCoupon = value
  },
  SET_ESTIMATE (state, value) {
    state.estimate = value
  },
  ENABLE_VAT_NUMBER_FIELD (state) {
    state.vatNumberFieldEnabled = true
  },
  SET_IS_COMPANY (state, isCompany) {
    state.isCompany = isCompany
  }
}

export const actions = {
  async enableVatNumberField ({ commit }) {
    await commit('ENABLE_VAT_NUMBER_FIELD')
  },
  async fetchPlans ({ commit }) {
    try {
      const response = await this.$axios.$get('/me/plans')

      const data = response.data
      data.plans.forEach((plan) => {
        plan.productType = plan.productGroup.split('-')[0]
      })

      await commit('SET_PLAN_DATA', data)
    } catch (e) {
      // If 403 returned, it means that the current
      // account cannot upgrade it's subscription.
      // Return just empty array so the subscribe page
      // can show error message based on that
      if (e?.response?.data?.statusCode === 403) {
        await commit('SET_PLAN_DATA', {
          plans: [],
          features: []
        })
      } else {
        throw e
      }
    }
  },
  async selectPlan ({ commit }, plan) {
    await commit('SET_SELECTED_PLAN', plan)
  },
  async save ({ dispatch }, { companyId, data }) {
    await this.$axios.$post(`/me/companies/${companyId}/subscription`, data)
    await dispatch('auth/fetchCompany', null, { root: true })
    await dispatch('auth/fetchFeatures', null, { root: true })
  },
  async clear ({ commit }) {
    await commit('CLEAR')
  },
  async fetchEstimate ({ rootGetters, commit, state }) {
    await commit('SET_ESTIMATE', null)

    const company = await rootGetters['auth/company']
    const data = {
      plan: state.selectedPlan?.id
    }
    if (state.activeCoupon) {
      data.coupon = state.activeCoupon?.id
    }
    const estimate = await this.$axios.$post(`/me/companies/${company.id}/subscription/estimate`, data)
    const { activeCoupon } = estimate
    await commit('SET_ACTIVE_COUPON', activeCoupon)
    await commit('SET_ESTIMATE', estimate)
  }
}
