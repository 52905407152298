const SESSION_KEY = 'current-partner-data'

export default async function ({ store, route, $axios }) {
  const currentPartnerTypeInQuery = route.query.partner

  let partner = null

  // Fetch from sessionStorage if exists. After fetch, we have to make sure that
  // the partner matches the one in query. If there is no partner in query,
  // we can use the cached result
  const cachedPartnerRaw = sessionStorage.getItem(SESSION_KEY)
  const cachedPartner = cachedPartnerRaw ? JSON.parse(cachedPartnerRaw) : null
  if (cachedPartner) {
    if (currentPartnerTypeInQuery) {
      if (cachedPartner.attributes.type === currentPartnerTypeInQuery) {
        partner = cachedPartner
      } else {
        sessionStorage.removeItem(SESSION_KEY)
      }
    } else {
      partner = cachedPartner
    }
  }

  // If no partner yet, try to fetch based on current partner in query
  if (!partner && currentPartnerTypeInQuery) {
    try {
      const response = await $axios.$get(`/partners/byType/${currentPartnerTypeInQuery}`)
      partner = response.data
    } catch (e) {}
  }

  sessionStorage.setItem(SESSION_KEY, JSON.stringify(partner))
  await store.commit('partner/SET_PARTNER', partner)
}
