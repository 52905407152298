import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import { v4 as uuid } from 'uuid';
import { GTM_EVENT_CONTRACT_COMPLETED, GTM_EVENT_ACTIVATE_SUBSCRIPTION, GTM_EVENT_UPGRADE_SUBSCRIPTION, GTM_EVENT_NEW_TRANSACTION, ACCOUNT_TYPE_COMPANY } from '~/utils/constants';
export default (function (_ref, inject) {
  var app = _ref.app;
  var $tracker = {
    /**
     * Called when contact form submitted
     */
    submitContactForm: function submitContactForm() {
      app.$gtm.push({
        event: 'submittedContactForm'
      });
      app.$ph.capture('contact_form_submitted');
    },
    /**
     * Called when contract has been finalized ("creation completed")
     */
    contractComplete: function contractComplete(_ref2) {
      var contractId = _ref2.contractId,
        templateName = _ref2.templateName;
      app.$log.debug('tracker:contractComplete', contractId, templateName);
      app.$gtm.push({
        event: GTM_EVENT_CONTRACT_COMPLETED,
        productID: contractId,
        productName: templateName
      });
    },
    /**
     * Called when user opens builder edit page
     */
    viewBuilder: function viewBuilder(_ref3) {
      var templateName = _ref3.templateName;
      app.$log.debug('tracker:viewBuilder', templateName);
      app.$intercom.call('trackEvent', 'view-contract-builder', {
        name: templateName
      });
    },
    /**
     * Called when user activates subscription for company.
     */
    trackActivation: function trackActivation(_ref4) {
      var planPriceId = _ref4.planPriceId,
        planName = _ref4.planName,
        companyId = _ref4.companyId,
        revenue = _ref4.revenue,
        currency = _ref4.currency,
        isUpgrade = _ref4.isUpgrade,
        coupon = _ref4.coupon;
      app.$gtm.push({
        event: isUpgrade ? GTM_EVENT_UPGRADE_SUBSCRIPTION : GTM_EVENT_ACTIVATE_SUBSCRIPTION,
        accountType: ACCOUNT_TYPE_COMPANY,
        planId: planPriceId // backwards compatibility
      });

      app.$ph.capture(isUpgrade ? 'subscription_upgraded' : 'subscription_created', {
        plan_id: planPriceId
      });

      // Analytics ecommerce tracking
      // Generate transaction
      var transactionId = companyId + '-' + Date.now();
      app.$gtm.push({
        event: GTM_EVENT_NEW_TRANSACTION,
        transactionId: transactionId,
        transactionTotal: revenue,
        transactionProducts: [{
          name: planName,
          category: 'Plan',
          sku: planPriceId,
          price: revenue,
          currency: currency,
          quantity: 1
        }]
      });

      // Send GA4 compatible purchase event
      app.$gtm.push({
        ecommerce: null
      }); // clean previous ecommerce object
      app.$gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          value: revenue,
          currency: currency,
          coupon: coupon,
          items: [{
            item_id: planPriceId,
            item_name: planName,
            item_category: 'plan',
            coupon: coupon,
            currency: currency,
            price: revenue,
            quantity: 1
          }]
        }
      });

      // Backend sends event to Intercom about activation
    },
    /**
     * Called when subscription activation fails
     */
    trackActivationFailed: function trackActivationFailed(_ref5) {
      var planPriceId = _ref5.planPriceId,
        errorMessage = _ref5.errorMessage;
      app.$intercom.call('trackEvent', 'activation-failed', {
        planPriceId: planPriceId,
        errorMessage: errorMessage
      });
    },
    /**
     * Called when subscription activation fails
     */
    choosedPlan: function choosedPlan(_ref6) {
      var planId = _ref6.planId,
        companyName = _ref6.companyName;
      app.$intercom.call('trackEvent', 'choosed-plan', {
        planId: planId,
        companyName: companyName
      });
    },
    /**
     * Called when user copies content from
     * contract builder preview
     */
    copyBuilderContent: function copyBuilderContent(templateName) {
      app.$intercom.call('trackEvent', 'copy-builder-content', {
        templateName: templateName
      });
      app.$ph.capture('builder_content_copied', {
        template_name: templateName
      });
    },
    createContract: function createContract(idField, templateId, templateName) {
      var _app$$ph$capture;
      app.$gtm.push({
        event: 'createdContract',
        templateName: templateName
      });
      app.$ph.capture('contract_created', (_app$$ph$capture = {}, _defineProperty(_app$$ph$capture, idField, templateId), _defineProperty(_app$$ph$capture, "template_name", templateName), _app$$ph$capture));
    },
    finalizeContract: function finalizeContract(method, templateName) {
      app.$gtm.push({
        event: 'finalizedContract',
        templateName: templateName,
        signatureMethod: method
      });
    },
    purchasedContract: function purchasedContract(templateId, templateName, price, currency) {
      // Analytics ecommerce tracking
      // Generate transaction
      var transactionId = uuid();
      app.$ph.capture('contract_purchased', {
        template_name: templateName
      });
      app.$gtm.push({
        event: GTM_EVENT_NEW_TRANSACTION,
        transactionId: transactionId,
        transactionTotal: price,
        transactionProducts: [{
          name: templateName,
          category: 'Contract',
          price: price,
          currency: currency,
          quantity: 1
        }]
      });

      // Send GA4 compatible purchase event
      app.$gtm.push({
        ecommerce: null
      }); // clean previous ecommerce object
      app.$gtm.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: transactionId,
          value: price,
          currency: currency,
          items: [{
            item_id: templateId,
            item_name: templateName,
            item_category: 'contract',
            currency: currency,
            price: price,
            quantity: 1
          }]
        }
      });
    }
  };
  inject('tracker', $tracker);
});