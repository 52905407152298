import Vue from 'vue';
import FullscreenPage from '~/components/hocs/fullscreen-page';
import CbPage from '~/components/hocs/cb-page';
import SPage from '~/components/hocs/s-page';
Vue.component('fullscreen-page', FullscreenPage);
Vue.component('cb-page', CbPage);
Vue.component('s-page', SPage);
export default (function (_ref, inject) {
  var app = _ref.app;
  var $page = {
    startLoading: function startLoading() {
      app.store.dispatch('startLoading');
    },
    stopLoading: function stopLoading() {
      app.store.dispatch('stopLoading');
    }
  };
  inject('page', $page);
});