import { clearState } from '~/utils/state'
import { populateRelationships } from '~/utils/api'
import { getActiveAccount } from '~/utils/cookie'
import { ACCOUNT_PERSONAL } from '~/utils/constants'

export const state = () => ({
  shareholders: [],
  shareholdersLoaded: false,
  shareholdersLoading: false,

  template: null,
})

export const mutations = {
  CLEAR: clearState(state()),
  SET_SHAREHOLDERS (state, shareholders) {
    state.shareholders = shareholders
    state.shareholdersLoaded = true
  },
  SET_SHAREHOLDERS_LOADING (state, loading) {
    state.shareholdersLoading = loading
  },
  SET_TEMPLATE (state, template) {
    state.template = template
  }
}

export const actions = {
  async fetchShareholders ({ commit, state }) {
    if (state.shareholdersLoading) { return }

    await commit('SET_SHAREHOLDERS_LOADING', true)
    const account = getActiveAccount()
    if (account !== ACCOUNT_PERSONAL) {
      const response = await this.$axios.$get(`/me/companies/${account}/shareholders?include=shares`).then(populateRelationships)
      await commit('SET_SHAREHOLDERS', response.data)
      await commit('SET_SHAREHOLDERS_LOADING', false)
    } else {
      await commit('SET_SHAREHOLDERS', [])
      await commit('SET_SHAREHOLDERS_LOADING', false)
    }
  }
}

export const getters = {
}
