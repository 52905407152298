/**
 * Returns current url without origin.
 *
 * --> /path?query=exists#andhash
 */
export const getCurrentUrlWithoutOrigin = () => location.href.replace(location.origin, '')

/**
 * Appends params as query string to provided path
 */
export const appendQuery = (path, params = {}) => {
  // No params, return just the path
  if (!Object.keys(params).length) {
    return path
  }

  const p = new URLSearchParams(params)
  return `${path}?${p.toString()}`
}

export const createSortedSearchParams = (params = {}) => {
  return new URLSearchParams(
    Object.keys(params).sort().reduce((acc, cur) => {
      if (!params[cur]) {
        return acc
      }
      return { ...acc, [cur]: params[cur] }
    }, {})
  )
}
